'use client'
import { PageContext, type TPageContext } from '@lib/analytics'
import type { ReactNode } from 'react'

export interface PageAnalyticsWrapperProps {
  readonly value: TPageContext
  readonly children: ReactNode
}

export default function PageAnalyticsWrapper({ value, children }: PageAnalyticsWrapperProps) {
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
